import { initTranslator } from "./translator";
import { initGsap } from "./gsapControls";
import { initCookiePolicy } from "./cookies";
import { initTypewriter } from "./typewriter";
// import { initDropdownNav } from "./dropdownNav";
// import { initMobileNav } from "./mobileNav";
import { initActiveNav, initCtaNav, initDropdownNav, initMobileNav } from "./nav";
import { initSmoothScroll } from "./smoothScroll";
import { initJobListingsFetch } from "./job-listings";
import { initContactForm } from "./contactForm";
import { delay, windowReady } from "./utils";

export default init = () => {
  windowReady(() => {
    delay(initGsap('Home'));
    initTranslator();
    initJobListingsFetch(false, 4);
    initSmoothScroll();
    initActiveNav();
    initCtaNav();
    initDropdownNav();
    initMobileNav();
    initCookiePolicy();
    initContactForm();
    delay(initTypewriter('Home'));
  });
};
